import { createSlice } from "@reduxjs/toolkit"

import { setCategories } from "./actions"

const initialState = {
	isLoading: false,
	data: [],
	isError: null,
}

const Categories = createSlice({
	name: "categories",
	initialState,
	extraReducers: builder => {
		builder.addCase(setCategories.fulfilled, (state, { payload }) => {
			// state.token = payload
		})
	},
})

export const CategoriesActions = {
	...Categories.actions,
	setCategories,
}

export default Categories.reducer
