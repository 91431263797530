import { createSlice } from "@reduxjs/toolkit"

import { getToken, getUser } from "./actions"

const initialState = {
	isLoading: false,
	user: JSON.parse(String(localStorage.getItem("user"))) ?? {},
	isError: null,
	token: localStorage.getItem("token") !== null && localStorage.getItem("token")
		? `${localStorage.getItem("token")}`
		: null
}

const User = createSlice({
	name: "user",
	initialState,
	extraReducers: builder => {
		builder.addCase(getUser.fulfilled, (state, { payload }) => {
			state.user = payload
			localStorage.setItem("user", JSON.stringify(payload))
		})
		builder.addCase(getToken.fulfilled, (state, { payload }) => {
			state.token = payload
			localStorage.setItem("token", payload)
		})
	},
})

export const UserActions = {
	...User.actions,
	getUser,
}

export default User.reducer
