import { configureStore } from '@reduxjs/toolkit';
import { api } from "../services/api";
import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"
import persistReducer from "redux-persist/es/persistReducer"

import userSlice from "./userSlice/userSlice";
import categorySlice from "./categorySlice/categorySlice";

const rootReducer = combineReducers({
    user: userSlice,
    categories: categorySlice,
    [api.reducerPath]: api.reducer,
})

const persistConfig = {
    key: "WILDBOX",
    storage,
    whitelist: ["settingsColumns"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = () => {
    return configureStore({
        reducer: persistedReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(api.middleware),
    })
}
