import React, { useState, Suspense } from 'react'

import { Box } from '@mui/material';
import { useRoutes, Navigate } from 'react-router-dom';

import LayoutHeader from '../layout/web/Header';
import Widgets from '../widgets';

import styles from "./index.module.scss";

const Home = React.lazy(() => import('../pages/Home'));
const Profile = React.lazy(() => import('../pages/Profile'));
const Search = React.lazy(() => import('../pages/Search'));
const Category = React.lazy(() => import('../pages/Category'));
const Favorite = React.lazy(() => import('../pages/Favorite'));
const Articule = React.lazy(() => import('../pages/Articule'));
const Busket = React.lazy(() => import('../pages/Busket'));

const AppRoutes = () => {
    const routes = useRoutes([
        { path: '/', element: <Home /> },
        { path: '/profile', element: <Profile /> },
        { path: '/products?search=:search', element: <Search /> },
        { path: '/category', element: <Category /> },
        { path: '/favorite', element: <Favorite /> },
        { path: '/articule/:id', element: <Articule /> },
        { path: '/busket', element: <Busket /> },
        { path: '*', element: <Navigate to="/" /> },
    ]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {routes}
        </Suspense>
    );
};

const App = () => {
    const [windgetsIsOpen, setWindgetsIsOpen] = useState(false)

    return <Box className={styles.root}>
        <Widgets
            onClose={() => setWindgetsIsOpen(false)}
            isOpen={windgetsIsOpen}
        />
        <LayoutHeader
            isOpen={windgetsIsOpen}
            open={setWindgetsIsOpen}
        />
        <Box px={2}>
            <AppRoutes />
        </Box>
    </Box>
}

export default App