import React, { Fragment, useMemo } from 'react'

import cn from "classnames";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Drawer } from '@mui/material'
import {
    NavigateNextOutlined as NextI,
    ArrowLeftOutlined as BackI
} from '@mui/icons-material';

import styles from "./index.module.scss";
import { useGetCategoriesQuery } from '../services/api';
import useWindowWidth from '../hooks/useWidth';

const Widgets = ({ isOpen, onClose }) => {
    const { search = "" } = useLocation()
    const navigate = useNavigate()
    const { data = [] } = useGetCategoriesQuery()
    const [child, setChild] = React.useState()
    const [childChild, setChildChild] = React.useState()

    const categoryName = useMemo(() => decodeURIComponent(search)?.replace("?", "") ?? "", [search])

    const windowWidth = useWindowWidth()

    const categoryData = useMemo(() => {
        if (windowWidth >= 960) {
            return data
        }

        if (childChild) {
            return data?.find(({ title = "" }) => title === child)
                ?.sections?.find(({ title = "" }) => title === childChild)
                ?.sections ?? []
        }

        if (child) {
            return data?.find(({ title = "" }) => title === child)?.sections ?? []
        }

        return data

    }, [windowWidth, data, child, childChild])

    const onMouseMove = (title) => {
        if (!child) {
            return setChild(title)
        }
        if (windowWidth <= 960) {
            if (!childChild) {
                return setChildChild(title)
            }
            onClose()
            navigate(`/category?${title}`.toLowerCase())
        }
    }
    const chech = (title) => {
        if (windowWidth >= 960) {
            setChild(title)
        } else {
            onMouseMove(title)
        }
    }

    const goBack = () => {
        if (childChild) {
            return setChildChild(undefined)
        }
        if (child) {
            return setChild(undefined)
        }
    }

    return (
        <Drawer
            placement={"left"}
            onClose={onClose}
            open={isOpen}
        >
            <Box className={styles.icon}>
                <font face="COURIER NEW"><b>KUM</b></font>
            </Box>

            <Box minWidth={240} height={10} />
            <Box className={styles.root}>
                <Box className={styles.categoryBox}>
                    {windowWidth <= 960 && (child || childChild) && <Fragment>
                        <Box
                            className={styles.linkItem}
                            width={"100%"}
                        >
                            <Box
                                width={"100%"}
                                onClick={goBack}
                                className={styles.item}
                            >
                                <Button
                                    variant="outlined"
                                    fullWidth
                                >
                                    <Box
                                        display={"flex"}
                                        gap={"1rem"}
                                        alignItems={"center"}
                                        position={"relative"}
                                    >
                                        <BackI style={{
                                            position: "absolute",
                                            left: -25
                                        }} />
                                        Назад
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    </Fragment>}

                    {categoryData.map(({ title = "" }, index) => (
                        <Box
                            className={styles.linkItem}
                            width={"100%"}
                            key={title + "_" + index}
                        >
                            <Box
                                width={"100%"}
                                onMouseMove={() => windowWidth > 960 && chech(title)}
                                onClick={() => windowWidth <= 960 && onMouseMove(title)}
                                className={cn(
                                    styles.item,
                                    title === child && styles.activeItem
                                )}
                            >
                                {title} {title === child && < NextI />}
                            </Box>
                        </Box>
                    ))}
                </Box>
                {windowWidth >= 960 ?
                    <Fragment>
                        {child && <Box
                            className={styles.childCategoryBox}
                            pl={child ?? 2}
                        >
                            {data.find(({ title = "" }) => title === child)?.sections?.map(({ title = "" }, index) => (
                                <Box
                                    className={styles.linkItem}
                                    width={"100%"}
                                    key={child + "_" + title + "_" + index}
                                >
                                    <Box
                                        width={"100%"}
                                        // onMouseMove={() => setChildChild(title)}

                                        onMouseMove={() => windowWidth > 960 && setChildChild(title)}
                                        onClick={() => windowWidth <= 960 && setChildChild(title)}


                                        className={cn(
                                            styles.item,
                                            title === childChild && styles.activeItem
                                        )}

                                    >
                                        {title} {title === childChild && < NextI />}
                                    </Box>
                                </Box>
                            ))}
                        </Box>}
                        {data?.find(({ title = "" }) => title === child)
                            ?.sections?.find(({ title = "" }) => title === childChild) && <Box
                                className={styles.childCategoryBox}
                                pl={childChild ?? 2}
                            >
                                {
                                    data?.find(({ title = "" }) => title === child)
                                        ?.sections?.find(({ title = "" }) => title === childChild)
                                        ?.sections?.map(({ title = "" }, index) => (
                                            <Link
                                                to={`category?${`${title}`.replace(" ", "_").replace(" ", "_").toLowerCase()}`}
                                                key={childChild + "_" + title + "_" + index}
                                                onClick={onClose}
                                                className={styles.linkItem}
                                            >
                                                <Box
                                                    width={"100%"}
                                                    className={cn(
                                                        styles.item,
                                                        categoryName === title && styles.activeItem
                                                    )}

                                                >
                                                    {title} {title === childChild && < NextI />}
                                                </Box>
                                            </Link>
                                        ))}
                            </Box>}
                    </Fragment>
                    : null
                }

            </Box>
        </Drawer>
    )
}

export default Widgets