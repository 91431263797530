import React, { Fragment, useLayoutEffect, useMemo, useState } from 'react'

import {
    Box,
    Input,
    InputAdornment,
    Typography,
} from '@mui/material'
import {
    MenuRounded as Menu,
    HomeRounded as Home,
    AccountCircleRounded as AccountCircle,
    SearchRounded as Search,
    ShoppingCartOutlined,
    FavoriteOutlined as FavoriteI,
    ArrowBackIosNewOutlined as BackI
} from '@mui/icons-material';

import Tooltip from '../../../components/Tooltip';
import { Link, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useWindowWidth from '../../../hooks/useWidth';

import styles from "./index.module.scss";
import Form from '../components/Form';



const Layout = ({ open: openMenu }) => {
    const { pathname } = useLocation()

    const navigate = useNavigate()
    const location = useLocation()
    const windowWidth = useWindowWidth()
    const navigationType = useNavigationType();

    const [loginDialogIsopen, setLoginDialogIsopen] = useState(false)
    const [canGoBack, setCanGoBack] = useState(false);
    const [search, setSearch] = useState("")

    const { user = null } = useSelector(store => store.user)

    useLayoutEffect(() => {
        if (navigationType !== 'PUSH' && window.history.state && window.history.state.idx > 0) return
        setCanGoBack(false);
    }, [navigationType, location]);

    const handleLoginDialogClose = () => {
        setLoginDialogIsopen(false);
    };
    const handleLoginDialogOpenn = () => {
        setLoginDialogIsopen(true);
    };

    const onChange = e => {
        setSearch(e.target.value);
        console.log(e.target.value);
    }

    const showHomeButton = useMemo(() => pathname !== "/", [pathname])
    const showGoBackButton = useMemo(() => windowWidth <= 960 && pathname !== "/" && !canGoBack !== "/", [pathname, canGoBack, windowWidth])

    return (
        <Fragment>
            <Box className={styles.root}>
                <Box className={styles.menu}>
                    <Box onClick={() => openMenu(true)} className={styles.item}>
                        <Tooltip
                            title={
                                <Typography color="inherit">
                                    Меню
                                </Typography>
                            }
                        >
                            <Menu />
                        </Tooltip>
                    </Box>
                    {showHomeButton && <Link to={"/"}>
                        <Box className={styles.item}>
                            <Tooltip
                                title={
                                    <Typography color="inherit">
                                        Главная страница
                                    </Typography>
                                }
                            >
                                <Home />
                            </Tooltip>
                        </Box>
                    </Link>}
                    {showGoBackButton &&
                        <Box
                            onClick={() => navigate(-1)}
                            className={styles.item}
                        >
                            <Tooltip
                                title={
                                    <Typography color="inherit">
                                        назад
                                    </Typography>
                                }
                            >
                                <BackI />
                            </Tooltip>
                        </Box>
                    }
                </Box>
                <Box>
                    <Box className={styles.menu}>
                        <Box className={`${styles.item} ${styles.searchInput}`}>
                            <Input
                                fullWidth
                                value={search}
                                onChange={onChange}
                                disableUnderline
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                }
                                placeholder="Поиск"
                            />
                        </Box>

                        {user?.id && <Link to={"/favorite"}>
                            <Box className={styles.item}>
                                <Tooltip
                                    title={
                                        <Typography color="inherit">
                                            Избранное
                                        </Typography>
                                    }
                                >
                                    <FavoriteI />
                                </Tooltip>
                            </Box>
                        </Link>}

                        {user?.id && <Link to={"/busket"}>
                            <Box className={styles.item}>
                                <Tooltip
                                    title={
                                        <Typography color="inherit">
                                            Объявление
                                        </Typography>
                                    }
                                >
                                    <ShoppingCartOutlined />
                                </Tooltip>
                            </Box>
                        </Link>}

                        {user?.id ? <Link to={"/profile"}>
                            <Box className={styles.item}>
                                <Tooltip
                                    title={
                                        <Typography color="inherit">
                                            Аккаунт
                                        </Typography>
                                    }
                                >
                                    <AccountCircle />
                                </Tooltip>
                            </Box>
                        </Link> : <Box className={styles.item}
                            onClick={handleLoginDialogOpenn}
                        >
                            <Tooltip
                                title={
                                    <Typography color="inherit">
                                        Аккаунт
                                    </Typography>
                                }
                            >
                                <AccountCircle />
                            </Tooltip>
                        </Box>}
                    </Box>
                </Box>
            </Box>
            <Form
                {...{
                    onClose: handleLoginDialogClose,
                    open: loginDialogIsopen
                }}
            />

        </Fragment >
    )
}

export default Layout