import React from "react";
import { Tooltip as Tooltip_, styled, tooltipClasses } from "@mui/material";

const Tooltip = styled(({ className, ...props }) => (
    // eslint-disable-next-line react/jsx-pascal-case
    <Tooltip_ {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default Tooltip