import React from 'react'

import {
    Box,
    TextField,
    Button,
    Grid,
    Container
} from '@mui/material'
import NumberInput from '../../../../components/NumberInput';

function RegisterForm({ onSubmit = e => e }) {
    const [phoneNumber, setPhoneNumber] = React.useState();
    const [useName, setUseName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [disabled, setDisabled] = React.useState(true)

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            phoneNumber: `+${phoneNumber}`,
            password,
            email,
            useName
        })
    };

    return (
        <Container maxWidth="xs">
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <NumberInput
                            value={phoneNumber}
                            setDisabled={setDisabled}
                            onChange={setPhoneNumber}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Имя"
                            name='name'
                            variant="outlined"
                            value={useName}
                            onChange={(e) => setUseName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Email"
                            name='email'
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Пароль"
                            type="password"
                            name='password'
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={disabled || password.length < 6}
                        >
                            <Box
                                py={"10px"}
                                component={"span"}
                            >
                                Зарегистрироваться
                            </Box>
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}


export default RegisterForm