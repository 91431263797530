import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { getToken, getUser } from '../store/userSlice/actions';
// import { setCategories } from '../store/categorySlice/actions';
import categories from "./categories.json";

// Функция для установки токена
const setToken = (token) => {
    localStorage.setItem('token', token);
};

// Создаем экземпляр Axios
const API = axios.create({
    // baseURL: 'http://localhost:8000/api/v1/',
    baseURL: 'https://stroi.ermekov.org/api/v1/',
});

// Добавляем интерсептор для добавления токена к каждому запросу
API.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Добавляем интерсептор для обновления токена при его истечении
API.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const response = await axios.post('/refresh_token', {
                token: localStorage.getItem('token'),
            });
            const newToken = response.data.token;

            setToken(newToken);
            API.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            return API(originalRequest);
        } catch (refreshError) {
            // Обработка ошибки обновления токена
            console.error('Не удалось обновить токен', refreshError);
        }
    }
    return Promise.reject(error);
});




export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: "" }),
    endpoints: (builder) => ({
        login: builder.mutation({
            queryFn: async ({ dispatch, phone_number, password }) => {
                const response = await API.post('/login', {
                    phone_number,
                    password,
                });
                if (response?.data?.token) {
                    localStorage.setItem("user", JSON.stringify(response?.data?.user))
                    localStorage.setItem("token", JSON.stringify(response?.data?.token))
                    dispatch(getToken({ token: response?.data?.token }))
                    dispatch(getUser({ user: response?.data?.user ?? {} }))
                }
                return response
            }
        }),
        register: builder.mutation({
            queryFn: async ({
                dispatch,
                phone_number,
                password,
                email,
                username
            }) => {
                const response = await API.post('/register', {
                    phone_number,
                    password,
                    email,
                    username
                });
                if (response?.data?.token) {
                    dispatch(getToken({ token: response?.data?.token }))
                    dispatch(getUser({ user: response?.data?.user ?? {} }))
                }
                return response
            }
        }),
        getCategories: builder.query({
            queryFn: async () => categories
        }),
        getProducts: builder.query({
            queryFn: async (props) => {
                const {
                    count = 10,
                    offset = 0,
                    length,
                    ids,
                    category,
                    fetch = true,
                    advertising,
                    user_id
                } = props
                if (!fetch) return 0;
                try {
                    const response = await API.get('/products', {
                        params: {
                            count: count,
                            offset: offset,
                            length,
                            ids,
                            category,
                            advertising,
                            user_id
                        }
                    });
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    throw error;
                }
            }
        }),
        protected: builder.query({
            queryFn: async ({ count = 10, offset = 0 }) => {
                try {
                    const response = await API.get('/products', {
                        params: {
                            count: count,
                            offset: offset
                        }
                    });
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    throw error;
                }
            }
        }),
        deleteProduct: builder.mutation({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.delete(`/delete_product/${id}`);
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    throw error;
                }
            }
        }),
        addFavoriteProduct: builder.mutation({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.post(`/add_to_favorites/${id}`);
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    throw error;
                }
            }
        }),
        deleteFavoriteProduct: builder.mutation({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.delete(`/delete_from_favorites/${id}`);
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    throw error;
                }
            }
        }),

        getFavoriteProducts: builder.query({
            queryFn: async ({ count = 10, offset = 0, length, userId }) => {
                try {
                    const response = await API.get(`/get_favorite_products/${userId}`, {
                        params: {
                            count,
                            offset,
                            length,
                        }
                    });
                    const data = response?.data
                    const ids = (data?.products ?? [])?.map(({ product_id }) => product_id)?.join(",")
                    if (ids?.length) {
                        const productData = await API.get('/products', {
                            params: {
                                ids
                            }
                        });

                        return productData
                    }
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    throw error;
                }
            }
        }),
        addToBusket: builder.mutation({
            queryFn: async ({ id, quantity, userId }) => {
                try {
                    const response = await API.post(`/add_to_cart/`, { product_id: id, quantity, user_id: userId });
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    throw error;
                }
            }
        }),

        getBusketProducts: builder.query({
            queryFn: async ({ page = 1, per_page = 10, length, user_id }) => {
                try {
                    const response = await API.get(`/get_cart_items/${user_id}`, {
                        params: {
                            length,
                            offset: page - 1,
                            count: per_page
                        }
                    });

                    const data = await response.data;

                    const ids = (data?.cart_items ?? [])?.map(({ product_id }) => product_id)?.join(",")
                    if (ids?.length) {
                        const productData = await API.get('/products', {
                            params: {
                                ids
                            }
                        });

                        const arr = (response?.data?.cart_items ?? []).map(item => {
                            const obj = (productData?.data?.products ?? [])
                                ?.find(({ id }) => id === item.product_id) ?? {}
                            return {
                                ...obj,
                                ...item,
                            }
                        });

                        return {
                            data: arr,
                        }
                    }

                    return { data: response?.data?.cart_items ?? [], };
                } catch (error) {
                    console.error('Error fetching products:', error);
                    return { error: { status: 'CUSTOM_ERROR', error } };
                }
            }
        }),
        deleteBusketProduct: builder.mutation({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.delete(`/delete_from_basket/${id}`);
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    throw error;
                }
            }
        }),

        getMainCategories: builder.query({
            queryFn: async () => {
                try {
                    const response = await API.get(`/advertised-categories`, { params: {} });
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    return { error: { status: 'CUSTOM_ERROR', error } };
                }
            }
        }),
        getUserData: builder.query({
            queryFn: async () => {
                try {
                    const response = await API.get(`/user`, { params: {} });
                    return response
                } catch (error) {
                    console.error('Error fetching products:', error);
                    return { error: { status: 'CUSTOM_ERROR', error } };
                }
            }
        }),
        updateToSeller: builder.mutation({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.patch(`/user/seller/${id}`, { "role": "seller" });
                    return response
                } catch (error) {
                    console.error('Error patching user:', error);
                    return { error: { status: 'CUSTOM_ERROR', error } };
                }
            }
        }),
    }),
});

export const {
    useUpdateToSellerMutation,
    useGetUserDataQuery,
    useGetMainCategoriesQuery,
    useFetchMBankDepositsQuery,
    useLoginMutation,
    useGetCategoriesQuery,
    useGetProductsQuery,
    useDeleteProductMutation,
    useAddFavoriteProductMutation,
    useGetFavoriteProductsQuery,
    useDeleteFavoriteProductMutation,
    useAddToBusketMutation,
    useGetBusketProductsQuery,
    useDeleteBusketProductMutation
} = api;