import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import styles from "./index.module.scss";

const NumberInput = ({
    value = "",
    onChange: setValue,
    setDisabled
}) => {

    const onChange = (value) => {
        setValue(value)
        setDisabled(value.length !== 12 || !`${value}`.includes("996"))
    }

    return <PhoneInput
        label="Номер телефона"
        value={value}
        placeholder="+996 (700) 11-22-33"
        isValid={(value) => {
            if (!value.match(/996/)) {
                return `Введите верный код: +${value.slice(0, 3)}`
            } else if (value.match(/1234/)) {
                return false;
            } else {
                return true;
            }
        }}
        searchNotFound="Введите номер телефона"
        inputProps={{
            name: 'phone',
            required: true,
            autoFocus: true
        }}
        className={styles.phoneInput}
        onChange={onChange}
        masks={{ kg: '(...) ..-..-..' }}
        country={'kg'}
        onlyCountries={['kg']}
        areaCodes={{ kg: ['996'] }}
    />
}


export default NumberInput