import React, { useState } from 'react'

import {
    Box,
    Dialog,
    Tabs,
    Tab
} from '@mui/material'
import LoginForm from '../Login'
import RegisterForm from '../Register'
import { api } from '../../../../services/api'
import { useDispatch } from 'react-redux'

const titles = ["Войти", "Зарегистрироваться"]

const Form = ({ onClose: handleLoginDialogClose, open: loginDialogIsopen }) => {
    const dispatch = useDispatch()

    const [login] = api.useLoginMutation()
    const [register] = api.useRegisterMutation()

    const [tabValue, setTabValue] = useState("Войти")

    const handleLogin = ({ phoneNumber, password }) => {
        login({ dispatch, phone_number: phoneNumber, password })
        handleLoginDialogClose()
    }

    const handleRegister = async ({
        phoneNumber,
        password,
        email,
        useName
    }) => {
        await register({
            dispatch,
            phone_number: phoneNumber,
            password,
            email,
            username: useName
        })
        handleLoginDialogClose()
    }


    return (
        <Dialog
            onClose={handleLoginDialogClose}
            open={loginDialogIsopen}
        >
            <Box p={2}>
                <Tabs
                    value={tabValue}
                    onChange={(_, e) => setTabValue(e)}
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}
                >
                    {titles.map(item => (
                        <Tab
                            key={item}
                            value={item}
                            label={item}
                        />
                    ))}
                </Tabs>
                <Box mt={2}>
                    {tabValue === "Войти"
                        ? <LoginForm onSubmit={handleLogin} />
                        : <RegisterForm onSubmit={handleRegister} />
                    }
                </Box>
            </Box>
        </Dialog>
    )
}

export default Form